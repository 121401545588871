.introText {
    position: relative;
    overflow: hidden;
    height: auto;
    padding: .5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    font-size: 1rem;
    font-weight: 300;
    color: gray;
}



.navbar {
    height: 5.4rem;
    width: 100vw;
    margin: 0 auto;
    padding: 1rem 12rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 300;
   
    animation-name: transition;
    animation-duration: 5s;
    background-color: #111;
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.cjText {
    font-size: 1.5rem;
    color: white
}

.seText {
    color: gray;
    font-size: 1rem;
    font-weight: 300;
}

.yellowText3 {
    color: rgb(238, 255, 0);
}

@keyframes transition {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;

    }
}

.logo {
    object-fit: cover;
    height: 3.4rem;
    width: 5.7rem;
    cursor: pointer;


}

.desktopMenuListItem {
    margin: 1rem;
    cursor: pointer;
    position: relative;
    transform: translateZ(0);
    color: white;
    animation-duration: 1s;
    padding-bottom: .5rem;
}

.desktopMenuListItem:before {
    content: "";
    position: absolute;
    z-index: -1;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid yellow;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scaleX(0);
    transition: 300ms ease-out;
}

.desktopMenuListItem:hover {
    color: yellow;

}

.desktopMenuListItem:hover::before {
    transform: scaleX(1);

}



.icons {
    font-size: 2rem;
    margin: .5rem;
    cursor: pointer;
    color: white;
}

.icons:hover {
    color: yellow;

}

.active {
    color: yellow;
 
}

@media only screen and (min-width: 2560px) {
    .navbar {
        height: 9rem;
        width: 100vw;
        margin: 0 auto;
        padding: 1rem 12rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: -webkit-sticky;
        top: 0;
        z-index: 300;
        background-color: black;
        color: white;
        box-shadow: 2px 2px 2px black;
        animation-name: transition;
        animation-duration: 5s;
    }

    .logo {
        object-fit: cover;
        height: 5rem;
        width: 8rem;
        cursor: pointer;


    }

    .desktopMenuListItem {
        margin: 2rem;
        cursor: pointer;
        position: relative;
        transform: translateZ(0);
        color: white;
        animation-duration: 1s;
        padding-bottom: .5rem;
        font-size: 2rem;
    }

    .icons {
        font-size: 3rem;
        margin: 1rem;
        cursor: pointer;
    }

}


@media only screen and (max-width: 1035px) {
    .navbar {
        height: auto;
        width: 100vw;
        padding: 1rem 5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: sticky;
        top: 0;
        z-index: 300;

        animation-name: transition;
        animation-duration: 5s;
    }


    
}

@media only screen and (max-width: 750px){
    .cjText {
        font-size: 1.2rem;
        color: white
    }
    
    .seText {
        color: gray;
        font-size: .7rem;
        font-weight: 200;
    }

  
    
}


@media only screen and (max-width: 650px) {
    .navbar {
        height: 4rem;
        width: 100vw;
        padding: 1rem 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: sticky;
        top: 0;
        z-index: 300;

    }

   

    .desktopMenuListItem {
        font-size: 15px;
        margin: 6px;
        cursor: pointer;
        position: relative;
        transform: translateZ(0);
        animation-duration: 1s;
        padding-bottom: .5rem;

        padding-right: 4px;
        font-weight: bold;
    }

    .icons {
        font-size: 1.5rem;
        margin: .3rem;
        cursor: pointer;
    }

    .introText {
    display: flex;
    flex-direction: column;
   align-items: center;
   justify-content:center ;
  
 
}
}

@media only screen and (max-width: 500px) {
    .navbar {
        height: auto;
        width: 100vw;
        max-width: 30rem;
        margin: 0 auto;
        padding: .5rem 1rem;
        display: flex;
        align-items: start;
        flex-direction: column;
        justify-content: space-between;
        position: sticky;
        top: 0;
        z-index: 300;

    }

    .cjText {
        font-size: 1rem;
        color: white
    }
    
    .seText {
        color: gray;
        font-size: .8rem;
        font-weight: 200;
        margin-bottom: .5rem;
    }


    .desktopMenuListItem {
        font-size: 15px;
        margin: 7px;
        cursor: pointer;
        position: relative;
        transform: translateZ(0);
        animation-duration: 1s;
        padding-bottom: .5rem;
        border-right: 1px solid black;
        padding-right: 5px;
        font-weight: bolder;
       
    }

    .icons {
        font-size: 1.5rem;
        margin: 1rem;
        cursor: pointer;
    }
}


