.footer {
    height: 4rem;
    width: 100vw;
    margin: 0 auto;
    padding: 1rem 12rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 300;
    background-color: black;
    color: white;
    box-shadow: 2px 2px 2px black;
    animation-name: transition;
    animation-duration: 5s;
    box-shadow: .5px 0px 5px gray;
}



.dashRed {
    color: yellow;
}

.name {
    font-size: 1rem;
}

.centerFooter {
    margin: 1rem;
    cursor: pointer;
    position: relative;
    transform: translateZ(0);
    animation-duration: 1s;
    padding-bottom: .5rem;
}

.footerLogo {
    object-fit: cover;
    height: 1rem;
    width: 1rem;
    cursor: pointer;
    margin: 2px;
    position: relative;
    top: 5px;

}

.centerText {
    font-size: .8rem;
    font-weight: 300;
}

.rightText {
    font-size: .8rem;
    font-weight: 300;
    color: gray;
}

@media only screen and (max-width: 1281px) {
    .footer {
        padding: 1rem 5rem;
        height: auto;
    }
}

@media only screen and (max-width: 650px) {
    .footer {
        padding: 1rem 2rem;
        height: auto;
    }
}

@media only screen and (max-width: 650px) {
    .footer {
        padding: 1rem 2rem;
        height: auto;
        border-right: 1px solid yellow;
        display: flex;
        flex-direction: column;
    }
}