
.tittle2 {
    font-size: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
}

.yellowText2 {
    color: yellow;
    margin-right: 1rem;
}


.Experience {
    padding: 2rem 12rem;
}



@media only screen and (max-width: 1035px) {
    .Experience {
        padding: 1rem 5rem;
    }

    .tittle2 {
        font-size: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 3rem;
    }
    
}

@media only screen and (max-width: 650px) {
    .Experience {
        padding: 1rem 2rem;
    }
}


@media only screen and (max-width: 470px) {
    .Experience {
        padding: 1rem 1rem;
    }
}
