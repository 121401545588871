
.hr {
    color: red;
}



.homepage {
    padding: 2rem 12rem;
}



@media only screen and (max-width: 1035px) {
    .homepage {
        padding: 1rem 5rem;
    }
    
}

@media only screen and (max-width: 650px) {
    .homepage {
        padding: 1rem 2rem;
    }
}


@media only screen and (max-width: 470px) {
    .homepage {
        padding: 1rem 1rem;
    }
}