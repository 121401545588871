.alignItems2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.profilePic {
    border-radius: 50%;
    object-fit: cover;
    width: 20rem;
    height: 20rem;
}
.profileText {
    text-align: justify;
    text-justify: inter-word;
    text-indent: 3em;
    font-size: 1rem;
    font-weight: 200;
    padding: 0 2rem;
    display: flex;
    margin: .5rem 7rem;
    padding: 1.5rem 2rem;
    border-radius: 0.5rem;
 
}

.profileL {
    font-size: 1.2rem;
}

.alignText3{
    margin: 1rem;
}

.grayText {
    font-weight: 200;
}









@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-family: "Montserrat";
}
.container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
}
.timeline {
  width: 80%;
  height: auto;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
}

.timeline ul {
  list-style: none;
}
.timeline ul li {
  padding: 20px;
  background-color: #1e1f22;
  color: white;
  border-radius: 10px;
  margin-bottom: 20px;
}
.timeline ul li:last-child {
  margin-bottom: 0;
}
.timeline-content h1 {
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 10px;
}
.timeline-content p {
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;
}
.timeline-content .date {
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 10px;
  letter-spacing: 2px;
}

.grayText {
    color: gray;
    font-weight: 200;
    margin-bottom: .2rem;
}


@media only screen and (max-width: 1280px) {
    .alignItems2 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .profileText {
        text-align: justify;
        text-justify: inter-word;
        text-indent: 3em;
        font-size: 1rem;
        font-weight: 200;
        padding: 0 2rem;
        display: flex;
        margin: .5rem 1rem;
        padding: 0rem 0rem;
        border-radius: 0.5rem;
     
    }
    
}




@media only screen and (min-width: 768px) {
  .timeline:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background-color: gray;
  }
  .timeline ul li {
    width: 50%;
    position: relative;
    margin-bottom: 50px;
  }
  .timeline ul li:nth-child(odd) {
    float: left;
    clear: right;
    transform: translateX(-30px);
    border-radius: 20px 0px 20px 20px;
  }
  .timeline ul li:nth-child(even) {
    float: right;
    clear: left;
    transform: translateX(30px);
    border-radius: 0px 20px 20px 20px;
  }
  .timeline ul li::before {
    content: "";
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: gray;
    top: 0px;
  }
  .timeline ul li:nth-child(odd)::before {
    transform: translate(50%, -50%);
    right: -30px;
  }
  .timeline ul li:nth-child(even)::before {
    transform: translate(-50%, -50%);
    left: -30px;
  }
  .timeline-content .date {
    position: absolute;
    top: -30px;
  }
  .timeline ul li:hover::before {
    background-color: yellow;
  }
}
