.projectPage {
    position: relative;
    
    
    border-radius: 2%;
}

.projectItem {
    padding: 2rem 12rem;
}

.projectTitle {

    font-size: 3.5rem;
    display: flex;
    align-items: ce1nter;
    justify-content: center;
    position: relative;
}

.yellowText {
    color: yellow;
    margin-right: 1rem;
}

@media only screen and (max-width: 1600px) {
    .projectItem {
        padding: auto 12remrem;
        box-shadow: none;
        border-radius: 2%;
    }
    
}

@media only screen and (max-width: 1035px) {
    .projectItem {
        padding: 1rem 5rem;
    }
    
}

@media only screen and (max-width: 650px) {
    .projectItem {
        padding: 1rem 2rem;
    }

    .projectTitle {
        font-size: 2rem;
        display: flex;
        align-items: ce1nter;
        justify-content: center;
        position: relative;
    }
}


@media only screen and (max-width: 470px) {
    .projectItem {
        padding: 1rem 1rem;
    }
}