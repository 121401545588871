.AllProjects {
    position: relative;
    margin: 4rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    height: auto;
}

.project1 {
    margin: 0 auto;
    display: flex;
    align-items: start;
    flex-direction: row;
}

.projectimg1 {
    width: 42rem;
    height: 23rem;
}

.projectGroupText {
    display: flex;
    align-items: start;
    flex-direction: column;
    max-width: 100%;
    margin: 1rem;
}

.imgTitle {
    color: white;
    display: flex;
    align-items: start;
    font-size: 2rem;
}

.suppText1 {
    font-size: 1.2rem;
    color: gray;
    font-weight: 300;
    margin-bottom: 2rem;

}

.desText {
    font-size: 1.2rem;
    font-weight: 300;
}

.techText1 {
    margin-top: .5rem;
    font-size: 1rem;
    font-weight: 300;
}

.iconText {
    margin: auto .2rem;
    height: 20px;
    width: 20px;
}

.buttons {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 2rem;
}

.buttonProject {
    cursor: pointer;
    margin-right: 1rem;
    font-weight: 400;
    font-size: 1rem;
    padding: .2rem .6rem;
    border-radius: .5rem;
}


.buttonProject:hover {
    background-color: gray;
    color: white;
}

.hr1 {
    position: relative;
}

.hralign {
    margin: 4rem auto;
}








@media only screen and (max-width: 1280px) {
    .projectimg1 {
        width: 72vw;
        height: 40vw;
    }

    .project1 {
        margin: 0 auto;
        display: flex;
        align-items: start;
        flex-direction: column;
    }

    .imgTitle {
        color: white;
        font-size: 2rem;
    }

    .imageAlign1 {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }
}

@media only screen and (max-width: 700px) {
    .projectimg1 {
        width: 80vw;
        height: 43vw;
    }
}

@media only screen and (max-width: 450px) {
    .projectimg1 {
        width: 92vw;
        height: 48vw;
    }
}